import React from "react";
import ContactForm from "../Components/ContactForm/ContactForm";
import { Phone, Envelope } from "react-bootstrap-icons";
import "./Home.css";
import Header from "../Images/Header.jpg";

export default function Entertainment() {
  return (
    <div className="Home">
      <div className="lander">
        <div className="body">
          {/* <h1 className="main">Helmut Fricker</h1> */}

          <div className="info">
            <h2 className="">World Class Musician and Entertainer</h2>
            {/* <Header /> */}
            <img src={Header} alt="Header" />
            <p className="text">
              Available for public and private performances. You can see
              Helmut's public performances listed below including my appearances
              on the Beaver Creek Plaza throughout the winter. More details
              below.
              <br />
              <span className="strong">
                Just listen for the famous Helmut Fricker yodel!
              </span>
            </p>
            <hr className="line" />
            <h2 className="title">Public Performances</h2>
            <div className="performanceList">
              <h3 className="title">Beaver Creek Plaza:</h3>

              <h4 className="text">December 18th- March 29th</h4>
              <h3>
                Catch Helmut every Tuesday, Wednesday and Saturday from 1pm-3pm 
                <br />
                
                
              </h3>
              <h5>Located at the Northface test by the Ice Rink (top of the escalator by the Vilar Center)</h5>

              <h3 className="title">Sarah's Lounge at the Christiana Lodge in Vail:</h3>

              <h4 className="text">Starting December 13th</h4>
              <h3>
                Join Helmut every Friday Night from 4:30 - 6:30 throughout the Vail ski season
                <br />
                
              </h3>
            </div>


            <hr className="line" />
            <h2 className="title">Performance Inquiries</h2>
            <h4 className="text subtitle">
              Contact Helmut directly for pricing and schedule
            </h4>
            <p>
              <Phone /> (970) 471-2779
            </p>
            <p>
              <Envelope /> helmutfricker@gmail.com
            </p>
            <ContactForm />
          </div>
        </div>
      </div>
    </div>
  );
}
